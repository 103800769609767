/* CSS for HangUpConfirm component */

.custom-dialog {
  max-width: 600px !important; /* Set a maximum width for responsiveness */
  width: 100%; /* Ensures it takes up the full width on smaller screens */
}

.custom-modal-container {
  font-size: 18px;
}

.custom-modal-header {
  color: #dc3545;
  font-size: 30px;
  font-weight: 700 !important;
  letter-spacing: 1px;
}
.custom-modal-header button {
  font-size: 40px;
  font-weight: 400;
}
/* Add your custom styles for the modal buttons here */

.custom-modal-submit-btn {
  background-color: #dc3545 !important;
  border: none !important;
  font-weight: 700 !important;
  font-size: 20px !important;
  color: #fff;
}

.custom-modal-submit-btn:hover {
  background-color: #bb2d3b !important;
  border-color: #bb2d3b !important;
  color: #fff !important;
}

.custom-modal-close-btn {
  border: none !important;
  font-weight: 700 !important;
  background-color: #0d6efd !important;
  color: #fff;
  font-size: 20px !important;
}

.custom-modal-close-btn:hover {
  background-color: #0b5ed7 !important;
  border-color: #0b5ed7 !important;
  color: #fff !important;
}
.custom-modal-body-container {
  font-size: 20px !important;
}
