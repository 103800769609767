.webRtc-container {
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 20px;
  }
  .webRtc-container section {
    font-size: 40px;
    width: 70%;
  }
  .questionnaire_container {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    gap: 20px;
  }
  .questionnaire_container img {
    width: 130px;
    cursor: pointer;
  }
  .webRtc-container button {
    font-weight: bold;
    font-size: 18px;
  }
  