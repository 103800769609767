html {
  height: 100%;
  position: fixed;
  width: 100%;
}
body,
#root,
.App,
.container-fluid,
section {
  height: 100%;
}
body {
  font-weight: 500;
  font-family: "Roboto", sans-serif !important;
  color: #00538b;
  letter-spacing: 1px;
}
section .container-fluid {
  overflow: hidden;
  /* padding:0; */
}
section.welcome-section.Questionnaire p.qus-text {
  line-height: 55px;
  font-size: 40px;
  margin-top: 50px;
  text-align: center;
  letter-spacing: 2px;
  max-width: 980px;
}
.Form-section {
  justify-content: center;
  display: flex;
  text-align: left;
  margin: 50px 0;
}
section {
  text-align: center;
  /* padding: 80px 0;   */
  font-weight: 500;
  color: #00538b;
  /* height: 100vh; */
}
.welcome-section {
  background: rgb(0, 133, 185);
  background: linear-gradient(
    39deg,
    rgba(0, 133, 185, 1) 0%,
    rgba(2, 200, 107, 1) 100%
  );
  color: #fff;
}
.welcome-section .container,
section.instruction-section .container-fluid,
.confirmquestionnaire-section .container-fluid,
.callinginstruction-section .container-fluid,
section.Thankyou-section .container-fluid {
  display: flex;
  justify-content: center;
  align-items: center;
  height: calc(100% - 64px);
  max-width: 1280px;
}
.welcome-section h1 {
  font-size: 7vw;
}
.welcome-section p {
  font-size: 40px;
  padding: 0 65px;
  line-height: 55px;
  margin: 0 auto;
  text-align: center;
  letter-spacing: 2px;
  max-width: 870px;
}
.Form-section label {
  text-transform: uppercase;
  font-size: 16px;
  font-weight: 500;
}
.vid-icon iframe {
  height: calc(100vh - 143px);
  width: 100%;
  background: #000;
}
.vid-icon .videodiv > div {
  height: calc(100vh - 143px) !important;
  width: 100% !important;
  background: #000;
}
.button-sec {
  justify-content: center;
  display: flex;
  position: fixed;
  width: 100%;
  left: 0;
  bottom: 50px;
  padding: 0px 0;
}
.Form-section form {
  width: 400px;
}
.Form-section input {
  background: #fff;
  border: none;
  border-radius: 0;
  height: 50px;
}
.Form-section .form-group {
  margin-bottom: 20px;
}
.Form-section .invalid-feedback {
  font-size: 15px;
}
.button-sec .btn-primary,
.full-widthbutton-sec .btn-primary {
  border: 8px solid #fff;
  border-radius: 0;
  background: none;
  min-width: 200px;
  min-height: 63px;
  font-weight: bold;
  font-size: 21px;
  cursor: pointer;
}
section .img-fluid {
  max-width: 100%;
  max-height: 250px;
  height: auto;
}
section.video-section .img-fluid {
  max-width: 100%;
  height: calc(100% - 143px);
}
section.welcome-section.Questionnaire span {
  color: #fff;
  text-decoration: underline;
  font-size: 24px;
  margin: 50px 0 10px 0;
  text-align: center;
  font-weight: 500;
  display: inline-block;
}
.welcome-section p.qus-text {
  font-size: 40px;
  margin-top: 100px;
  /* text-align: left; */
}
section p {
  font-size: 40px;
  margin-bottom: 0;
}
.video-section p {
  color: #00538b;
  text-align: center;
  padding-left: 15px;
}
.video-section .row,
.instruction-section .row {
  align-items: center;
}
.instruction-section p {
  color: #00538b;
}
.full-widthbutton-sec {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #00538b;
  padding: 40px 0;
  position: fixed;
  bottom: 0;
  width: 100%;
  min-height: 143px;
  left: 0;
}
section.video-section {
  padding: 0;
}

section.confirmquestionnaire-section ul li {
  font-size: 40px;
  color: #00538b;
  text-align: left;
  list-style-type: none;
  padding-left: 15px;
}
.confirmquestionnaire-section .qus-text {
  margin-left: 20px;
  margin-top: 30px;
}
.access-code {
  margin-top: 50px;
}
.callinginstruction-section a {
  color: #00538b;
  text-decoration: underline;
  font-size: 21px;
  margin: 30px 0 10px 0;
  text-align: center;
  font-weight: 500;
  display: inline-block;
}
section.Thankyou-section .row {
  /* padding: 150px 0; */
}
section.Thankyou-section {
  padding: 0;
}
section.callinginstruction-section p.head-text {
  margin-bottom: 30px;
  font-size: 50px;
}
.Thankyou-text {
  margin-top: 70px;
}
a.click_dial.btn.btn-primary {
  display: none;
}
a.click_dial.btn.btn-primary {
  background: #5aaaf8;
  border: #5aaaf8;
  color: #fff;
  text-decoration: none;
}
button.dial_back.btn.btn-primary {
  margin-right: 1.5rem;
}
.dial-icon {
  margin-left: 15px;
  font-size: 30px;
  vertical-align: middle;
}

.loader-div {
  position: absolute;
  transform: translate(-50%, -50%);
  text-align: center;
  margin: 0 auto;
  top: 50%;
  left: 50%;
}
@keyframes load {
  0% {
    left: 0;
    width: 0px;
  }
  50% {
    left: 150px;
    width: 150px;
  }
  100% {
    left: 350px;
    width: 0px;
  }
}
.loader {
  position: relative;
  width: 350px;
  min-height: 8px;
  text-align: center;
  margin: 0 auto;
}

.loader::before {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 350px;
  min-height: 8px;
  border-radius: 50px;
  background-color: rgba(11, 11, 11, 0.5);
}

.loader::after {
  position: absolute;
  content: "";
  top: 0;
  left: 0;
  width: 30px;
  min-height: 8px;
  border-radius: 50px;
  background: linear-gradient(
    39deg,
    rgba(0, 133, 185, 1) 0%,
    rgba(2, 200, 107, 1) 100%
  );
  animation: load 1.5s linear infinite;
}
.loader p {
  display: block;
  margin: 0;
  padding: 15px;
  text-align: center;
  color: #888888;
  font-weight: bold;
}
.loader-div.error-msg {
  color: red;
  font-size: 18px;
  max-width: 350px;
  line-height: 30px;
  z-index: 99;
}
.not-email {
  cursor: pointer;
}

.animation-element {
  opacity: 0;
  position: relative;
}
.animation-element.slide-left {
  opacity: 0;
  -webkit-transition: opacity 1000ms cubic-bezier(0.25, 0.1, 0.25, 1),
    -webkit-transform 1000ms cubic-bezier(0.25, 0.1, 0.25, 1);
  transition: opacity 1000ms cubic-bezier(0.25, 0.1, 0.25, 1),
    -webkit-transform 1000ms cubic-bezier(0.25, 0.1, 0.25, 1);
  transition: opacity 1000ms cubic-bezier(0.25, 0.1, 0.25, 1),
    transform 1000ms cubic-bezier(0.25, 0.1, 0.25, 1);
  transition: opacity 1000ms cubic-bezier(0.25, 0.1, 0.25, 1),
    transform 1000ms cubic-bezier(0.25, 0.1, 0.25, 1),
    -webkit-transform 1000ms cubic-bezier(0.25, 0.1, 0.25, 1);
  -moz-transform: translate3d(-50px, 0px, 0px);
  -webkit-transform: translate3d(-50px, 0px, 0px);
  -o-transform: translate(-50px, 0px);
  -ms-transform: translate(-50px, 0px);
  transform: translate3d(-50px, 0px, 0px);
}
.animation-element.slide-left.in-view {
  opacity: 1;
  -moz-transform: translate3d(0px, 0px, 0px);
  -webkit-transform: translate3d(0px, 0px, 0px);
  -o-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate3d(0px, 0px, 0px);
}
.animation-element.slide-right {
  opacity: 0;
  -webkit-transition: opacity 1000ms cubic-bezier(0.25, 0.1, 0.25, 1),
    -webkit-transform 1000ms cubic-bezier(0.25, 0.1, 0.25, 1);
  transition: opacity 1000ms cubic-bezier(0.25, 0.1, 0.25, 1),
    -webkit-transform 1000ms cubic-bezier(0.25, 0.1, 0.25, 1);
  transition: opacity 1000ms cubic-bezier(0.25, 0.1, 0.25, 1),
    transform 1000ms cubic-bezier(0.25, 0.1, 0.25, 1);
  transition: opacity 1000ms cubic-bezier(0.25, 0.1, 0.25, 1),
    transform 1000ms cubic-bezier(0.25, 0.1, 0.25, 1),
    -webkit-transform 1000ms cubic-bezier(0.25, 0.1, 0.25, 1);
  -moz-transform: translate3d(420px, 0px, 0px);
  -webkit-transform: translate3d(420px, 0px, 0px);
  -o-transform: translate(420px, 0px);
  -ms-transform: translate(420px, 0px);
  transform: translate3d(420px, 0px, 0px);
}
.animation-element.slide-right.in-view {
  opacity: 1;
  -moz-transform: translate3d(0px, 0px, 0px);
  -webkit-transform: translate3d(0px, 0px, 0px);
  -o-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate3d(0px, 0px, 0px);
}
.animation-element.bounce-up {
  opacity: 0;
  -webkit-transition: opacity 1000ms cubic-bezier(0.25, 0.1, 0.25, 1),
    -webkit-transform 1000ms cubic-bezier(0.25, 0.1, 0.25, 1);
  transition: opacity 1000ms cubic-bezier(0.25, 0.1, 0.25, 1),
    -webkit-transform 1000ms cubic-bezier(0.25, 0.1, 0.25, 1);
  transition: opacity 1000ms cubic-bezier(0.25, 0.1, 0.25, 1),
    transform 1000ms cubic-bezier(0.25, 0.1, 0.25, 1);
  transition: opacity 1000ms cubic-bezier(0.25, 0.1, 0.25, 1),
    transform 1000ms cubic-bezier(0.25, 0.1, 0.25, 1),
    -webkit-transform 1000ms cubic-bezier(0.25, 0.1, 0.25, 1);
  -moz-transform: translate3d(0px, 50px, 0px);
  -webkit-transform: translate3d(0px, 50px, 0px);
  -o-transform: translate(0px, 50px);
  -ms-transform: translate(0px, 50px);
  transform: translate(0px, 50px);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}
.animation-element.bounce-up.in-view {
  opacity: 1;
  -moz-transform: translate3d(0px, 0px, 0px);
  -webkit-transform: translate3d(0px, 0px, 0px);
  -o-transform: translate(0px, 0px);
  -ms-transform: translate(0px, 0px);
  transform: translate3d(0px, 0px, 0px);
}
.animation-element.scale {
  opacity: 0;
  -moz-transition: all 900ms ease-out;
  -webkit-transition: all 900ms ease-out;
  -o-transition: all 900ms ease-out;
  transition: all 900ms ease-out;
  -moz-transform: scale(0.9);
  -webkit-transform: scale(0.9);
  -o-transform: scale(0.9);
  -ms-transform: scale(0.9);
  transform: scale(0.9);
  -webkit-backface-visibility: hidden;
  -moz-backface-visibility: hidden;
  backface-visibility: hidden;
}
.animation-element.scale.in-view {
  opacity: 1;
  -moz-transform: scale(1, 1);
  -webkit-transform: scale(1, 1);
  -o-transform: scale(1, 1);
  -ms-transform: scale(1, 1);
  transform: scale(1, 1);
}
.animation-element.bottom_smooth_up.in-view {
  opacity: 1;
  -webkit-transform: translate(0, 0);
  -ms-transform: translate(0, 0);
  transform: translate(0, 0);
}
.animation-element.bottom_smooth_up {
  -webkit-transition: opacity 1000ms cubic-bezier(0.25, 0.1, 0.25, 1),
    -webkit-transform 1000ms cubic-bezier(0.25, 0.1, 0.25, 1);
  transition: opacity 1000ms cubic-bezier(0.25, 0.1, 0.25, 1),
    -webkit-transform 1000ms cubic-bezier(0.25, 0.1, 0.25, 1);
  transition: opacity 1000ms cubic-bezier(0.25, 0.1, 0.25, 1),
    transform 1000ms cubic-bezier(0.25, 0.1, 0.25, 1);
  transition: opacity 1000ms cubic-bezier(0.25, 0.1, 0.25, 1),
    transform 1000ms cubic-bezier(0.25, 0.1, 0.25, 1),
    -webkit-transform 1000ms cubic-bezier(0.25, 0.1, 0.25, 1);
  opacity: 0.01;
  -webkit-transform: translate(0, 50px);
  -ms-transform: translate(0, 50px);
  transform: translate(0, 50px);
}
.animation-element.fadein {
  transition-timing-function: ease-in;
  transition-duration: 0.7s;
  opacity: 0;
}
.animation-element.fadein.in-view {
  opacity: 1;
  transition-timing-function: ease-in;
  transition-duration: 0.7s;
}
section.sectionClass {
  position: absolute;
  width: 100%;
  right: -100%;
  top: 0;
  z-index: -1;
  visibility: hidden;
  opacity: 0;
  transition: visibility 1s ease-in-out;
}
section.sectionClass.Show {
  visibility: visible;
  z-index: 9;
  opacity: 1;
  right: 0;
}
.welcome-section label.error-msg {
  display: block;
  font-size: 12px;
  text-align: center;
  color: red;
  margin: 0;
  text-transform: capitalize;
  background: #fff;
  padding: 5px;
}
.Questionnaire p.sub-head {
  font-size: 50px;
  margin: 0 auto;
}
.vid-icon {
  padding-right: 0 !important;
}
.is_participant_allowed_message_class {
  max-width: 500px;
  margin: 0 auto;
  padding: 0 15px;
}
.is_participant_allowed_message_class .error-msg {
  display: block;
  font-size: 18px;
  text-align: center;
  color: red;
}
@media only screen and (max-width: 1280px) {
  .welcome-section .container,
  section.instruction-section .container-fluid,
  .confirmquestionnaire-section .container-fluid,
  .callinginstruction-section .container-fluid,
  section.Thankyou-section .container-fluid {
    max-width: 1100px;
  }
}
@media screen and (min-width: 1200px), screen and (max-height: 670px) {
  section.callinginstruction-section p {
    font-size: 40px;
  }
}
@media only screen and (max-width: 1024px) {
  .button-sec .btn-primary,
  .full-widthbutton-sec .btn-primary {
    border: 6px solid #fff;
  }
}

@media only screen and (max-width: 767px) {
  section.sectionClass {
    position: absolute;
    width: 100%;
    right: -100%;
    top: 0;
    z-index: -1;
    visibility: visible;
    opacity: 0;
    transition-timing-function: cubic-bezier();
    transition-property: right;
    transition-duration: 0.5s;
  }
  section.sectionClass.Show {
    z-index: 99;
    right: 0;
    opacity: 1;
  }
  /* section.sectionClass.defaultbackshow {
    position: absolute;
    width: 100%;
    left: -100%;
    right:auto;
    top: 0;
    z-index: -1;
    visibility: visible;
    opacity: 0;
    transition-timing-function: cubic-bezier();
    transition-property: left;
    transition-duration : .5s;
  } */
  section.sectionClass.backShow {
    left: -100%;
    right: auto;
    transition-property: left;
    transition-duration: 0.5s;
  }
  section.sectionClass.Show.backShow {
    z-index: 99;
    left: 0;
    opacity: 1;
  }
}
@media screen and (min-width: 375px) and (max-width: 812px) and (orientation: landscape) {
  html:after {
    height: 100%;
    width: 100%;
    /* overflow-x: hidden; */
    position: absolute;
    top: 0;
    left: 0;
    content: "Landscape mode not available switch to Portrait mode";
    background: linear-gradient(
      83deg,
      rgba(0, 133, 185, 1) 25%,
      rgba(2, 200, 107, 1) 100%
    );
    color: #fff;
    z-index: 9999999;
    text-align: center;
    justify-content: center;
    display: flex;
    align-items: center;
    font-size: 20px;
    text-transform: uppercase;
    padding: 30px;
    line-height: 35px;
  }
}
@media only screen and (max-width: 768px) {
  section.video-section iframe,
  .vid-icon .videodiv > div {
    max-width: 100%;
    width: 100%;
    height: 500px !important;
  }
  .vid-txt {
    order: 2;
  }
  .vid-icon {
    order: 1;
    padding-left: 0 !important;
  }
  .welcome-section p {
    padding: 0 30px;
    line-height: 75px;
  }

  .welcome-section .container,
  section.instruction-section .container-fluid,
  .confirmquestionnaire-section .container-fluid,
  .callinginstruction-section .container-fluid {
    padding: 0 15px;
  }

  section p {
    font-size: 50px;
    padding: 15px;
  }
  .video-section p {
    padding: 30px;
    font-size: 60px;
  }
  .callinginstruction-section a {
    font-size: 20px;
  }
}
@media only screen and (max-width: 450px) {
  section .img-fluid {
    max-width: 80%;
    max-height: 150px;
  }
  section.welcome-section.Questionnaire p.qus-text {
    font-size: 20px;
    letter-spacing: 1px;
    line-height: 32px;
  }
  section.welcome-section.Questionnaire span {
    font-size: 16px;
    word-break: break-all;
  }
  section.callinginstruction-section p.head-text {
    font-size: 30px;
  }
  .welcome-section p.sub-head {
    font-size: 8vw;
    line-height: 45px;
    margin-bottom: 70px;
  }
  p.qus-text.sub-head {
    font-size: 8vw;
    line-height: 40px;
  }
  @keyframes load {
    0% {
      left: 0;
      width: 0px;
    }
    50% {
      left: 100px;
      width: 100px;
    }
    100% {
      left: 200px;
      width: 0px;
    }
  }
  .loader {
    width: 200px;
  }

  .loader::before {
    width: 200px;
  }
  .loader p {
    font-size: 13px;
  }
  a.click_dial.btn.btn-primary {
    display: inline-block;
  }
  button.finish_btn.btn.btn-primary {
    /* display: none; */
  }
  button.dial_back.btn.btn-primary {
    display: none;
  }
  .section {
    overflow: hidden;
    padding: 20px;
  }
  .welcome-section {
    background: rgb(0, 133, 185);
    background: linear-gradient(
      83deg,
      rgba(0, 133, 185, 1) 25%,
      rgba(2, 200, 107, 1) 100%
    );
  }

  .welcome-section h1 {
    font-size: 10vw;
    text-transform: uppercase;
  }
  .welcome-section p {
    font-size: 25px;
    padding: 0;
    line-height: 33px;
  }

  .Form-section {
    margin: 20px;
    margin-top: 60px;
  }
  .Form-section form {
    width: 100%;
  }
  .welcome-section p.qus-text {
    font-size: 25px;
    padding: 0 20px;
  }
  .video-section p {
    padding: 20px;
    font-size: 25px;
    margin: 0;
  }
  .instruction-section p {
    font-size: 25px;
    line-height: 40px;
    padding: 0 20px;
    margin-top: 30px;
  }
  .button-sec .btn-primary,
  .full-widthbutton-sec .btn-primary {
    min-width: 160px;
    min-height: 60px;
    font-weight: bold;
    font-size: 20px;
  }
  .full-widthbutton-sec {
    padding: 40px 0;
    min-height: auto;
  }
  .vid-txt p.qus-text {
    font-size: 9vw;
    text-align: center;
    line-height: 60px;
  }

  .heaphone-col p {
    margin-bottom: 10px;
  }
  section.confirmquestionnaire-section ul li {
    font-size: 25px;
    line-height: 60px;
  }
  section.confirmquestionnaire-section p {
    font-size: 25px;
    padding: 0 25px;
  }
  section p,
  section.callinginstruction-section p {
    font-size: 25px;
    padding: 0;
  }
  .callinginstruction-section span {
    font-size: 21px;
  }
  .access-code {
    padding: 20px;
  }
  section.video-section iframe,
  .vid-icon .videodiv > div {
    max-width: 100%;
    width: 100%;
    height: 230px !important;
  }
}

@media only screen and (max-width: 380px) {
  .welcome-section p {
    font-size: 25px;
    line-height: 35px;
  }
  .button-sec .btn-primary,
  .full-widthbutton-sec .btn-primary {
    min-width: 125px;
    min-height: 45px;
    font-size: 17px;
    border: 4px solid #fff;
  }
  /* .instruction-section p,p.qus-text,section.welcome-section.Questionnaire p.qus-text,.instruction-section p, section.welcome-section.Questionnaire p.qus-text {
  font-size: 25px;
  line-height: 40px;
} */
  .Form-section input {
    height: 40px;
  }

  .full-widthbutton-sec {
    padding: 20px 0;
  }
}
@media only screen and (max-width: 360px) {
  .welcome-section p.sub-head {
    font-size: 24px;
    line-height: 35px;
    margin-bottom: 15px;
  }
  section.welcome-section.Questionnaire span {
    margin-top: 30px;
  }
  section.welcome-section.Questionnaire p.qus-text {
    margin-top: 30px;
  }
  .welcome-section .container,
  section.instruction-section .container-fluid,
  .confirmquestionnaire-section .container-fluid,
  .callinginstruction-section .container-fluid,
  section.Thankyou-section .container-fluid {
    height: calc(100% - 85px);
  }
  .vid-txt p.qus-text {
    font-size: 24px;
    text-align: center;
    line-height: 35px;
  }
  section.callinginstruction-section p.head-text {
    font-size: 25px;
  }
  section.confirmquestionnaire-section ul {
    padding-left: 0;
  }
  .instruction-section p,
  section.confirmquestionnaire-section ul li,
  section.confirmquestionnaire-section p {
    font-size: 20px;
    line-height: 30px;
    padding: 0 20px;
    margin-top: 20px;
  }
  section p,
  section.callinginstruction-section p {
    font-size: 20px;
    line-height: 30px;
  }
  .callinginstruction-section a {
    font-size: 16px;
  }

  section.video-section iframe {
    max-width: 100%;
    width: 100%;
    height: calc(100vh - 56vh);
  }
}

.visible-button {
  display: block !important;
}

.hidden-button {
  display: none !important;
}