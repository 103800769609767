.thankyou-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  font-size: 17px;
  letter-spacing: 2px;
}
.thankyou-inner-container {
  min-width: 800px;
  min-height: 350px;
  border: 1px solid gainsboro;
  border-radius: 5px;
}

.thankyou-header-container {
  padding: 15px;
  font-weight: 500;
  background-color: #f5f5f5;
  color: #848484;
  display: flex;
  justify-content: right;
  align-items: center;
  border-bottom: 1px solid #dfd5d5;
}
.thankyou-main-container {
  padding: 20px;
  padding-bottom: 10px;
  color: #000;
  font-weight: normal;
}
.user-name {
  color: #000;
  font-weight: 500;
  font-size: 17px;
}
.thankyou-footer-container {
  padding: 20px;
  padding-top: 10px;
  color: #000;
  font-weight: 500;
}
.msg{
  margin-left: 20px
}
.thank-you-text{
  margin: 0px;
  margin-bottom: 4px;
}
p.close-window {
  text-align: center;
  font-size: 14px;
  font-weight: normal;
  background: #ddd;
  padding: 9px 5px;
  text-transform: capitalize;
}

@media (max-width: 768px) {
.thankyou-container {
    letter-spacing: normal;
  }
  .thankyou-inner-container{
    max-width: 90%;
    min-width: 90%;
    min-height: 290px;
  }
  .option-container{
    margin: 0;
  }
}