.having-touble-text {
  font-size: 20px;
  text-decoration: underline;
  cursor: pointer;

}
.select-checkbox {
  width: 15px;
  height: 15px;
}
.error-text .error {
  padding: 5px;
  background: #c94848;
  color: #fff;
  border-radius: 3px;
  text-align: center;
}
.error {
  color: red;
}

