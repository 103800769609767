.having-trouble-container {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
  font-size: 17px;
  letter-spacing: 2px;
  overflow-y: auto;
}

.having-trouble-container form {
  max-width: 800px;
  border: 1px solid gainsboro;
  border-radius: 5px;
}

.having-trouble-header-container {
  padding: 15px;
  font-weight: 500;
  background-color: #f5f5f5;
  color: #848484;
  display: flex;
  justify-content: right;
  align-items: center;
  border-bottom: 1px solid #dfd5d5;
}

.having-trouble-main-container {
  padding: 30px;
  padding-bottom: 0px;
  color: #000;
  font-weight: normal;
}

.having-trouble-footer-container-text {
  margin: 0px 20px;
  color: #3e3e3e;
  font-weight: 500;
}

.having-trouble-footer-container {
  padding: 20px 30px;
  display: flex;
  flex-direction: column;
}

.main-container-options {
  margin: 0px 30px;
}

.other-option-container {
  display: flex !important;
  justify-content: flex-start !important;
  align-items: flex-start !important;
}

.option-container {
  margin: 20px;
}

.option-text {
  margin: 1em 0 1em 2em;
}

.having-trouble-container .heading {
  color: black;
  font-size: 17px;
  font-weight: 500;
  margin-right: 5px;
}

.having-trouble-container input {
  margin: 5px 12px !important;
}

.textarea-container {
  flex: 1 !important;
}

.textarea-container textarea {
  width: 100%;
  padding: 10px;
}

.other-text-container {
  padding: 10px;
  padding-top: 0px;
  border: 1px solid #a1a1a1;
  border-radius: 5px;
  outline: none;
}

.having-trouble-container button {
  background: #0075ff;
  color: white;
  padding: 7px 35px;
  font-weight: 500;
  border: 1px solid #0075ff;
  border-radius: 5px !important;
  align-self: center;
  text-transform: uppercase !important;
}

.user-name {
  color: #3e3e3e;
  font-weight: 500;
  font-size: 20px;
}

.having-trouble-modal-header {
  color: #000000;
  font-size: 30px;
  font-weight: 700 !important;
  letter-spacing: 1px;
}

@media (max-width: 768px) {

  .having-trouble-container,
  .thankyou-container {
    letter-spacing: normal;
  }

  .having-trouble-container form,
  .thankyou-inner-container {
    max-width: 90%;
  }

  .option-container {
    margin: 0;
  }

  .option-container ul {
    padding-left: 20px;
  }
}

@media (max-width: 580px) {
  label.other-option-container {
    display: block !important;
  }

  .option-text {
    margin: 5px 0 25px 10px;
  }

  .having-trouble-footer-container {
    padding: 10px;
  }

  .textarea-container textarea {
    margin-top: 10px;
  }

  .having-trouble-footer-container-text {
    margin: 0px 0px 25px 25px;
  }

  .having-trouble-container button {
    margin-bottom: 10px;
  }
}

.having_modal_header .modal-title.h4 {
  font-size: 16px;
}

.modal-header {
  background: #007bff;
  color: #fff;
}

.modal-header button.close {
  color: #167af6;
  opacity: 1;
  background: #fff;
  height: 25px;
  width: 35px;
  padding: 0;
  border-radius: 50%;
  margin: -8px -8px;
}

.error-message {
  color: red;
}

.having_body_form_wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.having_body_form_wrapper .having_body_form_captcha {
  margin-bottom: 5px;
  font-size: 25px;
  font-weight: 500;
  font-style: italic;
}

.catcha_submit_btn {
  min-width: 150px;
}
.catcha_input_group{
  display: flex;
  flex-wrap: nowrap;
  width: 100%;
}
.catch_input_f {
  display: flex;
  margin-right: 10px;
}